






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import TurnoversTabContent from "@/components/for-turnovers-and-reports-view/TurnoversTabContent.vue";
import ReportsTabContent from "@/components/for-turnovers-and-reports-view/ReportsTabContent.vue";
import AnalysisTabContent from "@/components/for-turnovers-and-reports-view/AnalysisTabContent.vue";

@Component({
  components: {
    Page,
    XTabs,
    TurnoversTabContent,
    ReportsTabContent,
    AnalysisTabContent,
  },
})
class TurnoversAndReports extends Vue {
  titles = ["Обороты", "Отчеты", "Анализ потребления"];

  components = [TurnoversTabContent, ReportsTabContent, AnalysisTabContent];
}

export default TurnoversAndReports;
