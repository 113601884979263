



















import { Component, Vue } from "vue-property-decorator";
import XCard from "../SimpleCard.vue";
import ActionDropdown from "../ActionDropdown.vue";

@Component({
  components: { XCard, ActionDropdown },
})
class DocGetterCard extends Vue {}

export default DocGetterCard;
