




























import { AccountingPointsByObject } from "@/models/accounting-point";
import { Component, Prop, Vue } from "vue-property-decorator";
import XCard from "../SimpleCard.vue";

@Component({ components: { XCard } })
class ObjectList extends Vue {
  @Prop({ required: true })
  readonly pointsByObjects!: AccountingPointsByObject[];
}

export default ObjectList;
