
































import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDate, getDateByString } from "@/lib/date";
import { prepareMoney } from "@/assets/scripts/utils";
import { OrganizationTurnovers } from "@/models/organization";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: {
    XDataTable,
  },
})
class TurnoversTable extends Vue {
  [x: string]: any;

  @Prop({ required: true }) readonly turnoversList!: OrganizationTurnovers[];

  headers = [
    { text: "Месяц", value: "month", align: "start", class: "" },
    { text: "Вх.кредит", value: "inputCredit" },
    { text: "Вх.дебет", value: "inputDebet" },
    { text: "Потреблено", value: "consumed", class: "" },
    { text: "Начислено", value: "accrued" },
    { text: "Оплачено", value: "paid" },
    { text: "Исх.кредит", value: "outputCredit" },
    { text: "Исх.дебет", value: "outputDebit" },
  ];

  // Свойства, использующиеся для контроля и управления пагинацией.
  paginationProps = { page: 0, pageCount: 0, itemsPerPage: 0 };

  search = "";

  public get preparedHeaders() {
    return this.headers.map((header) => ({
      width: "5%",
      align: "end",
      class: "data-table__text_type_money",
      ...header,
    }));
  }

  public get preparedTurnoversByMonths(): Array<string[]> {
    if (!this.turnoversList.length) {
      return [];
    }

    const requiredKeys = [
      "месяц",
      "вхкредит",
      "вхдебет",
      "потреблено",
      "начислено",
      "оплата",
      "исхкредит",
      "исхдебет",
    ];

    // Извлечь необходимые данные из массива, полученного от сервера.
    const turnoversByMonths: Array<string[]> = this.turnoversList
      .slice()
      .reverse()
      .map((turnoversByMonth: OrganizationTurnovers) =>
        requiredKeys.map((key, i) => {
          const tdValue = turnoversByMonth[key];

          if (!i) return formatDate(tdValue, "monthAndYear");

          if (i === 2) return tdValue.toLocaleString();

          return prepareMoney(tdValue);
        })
      );

    /*
     * Подготовить данные к использованию в шаблоне компонента - таблицы, т.е.
     * создать массив, элементами которого являются массивы из строк,
     * каждый из которых представляет строку таблицы.
     */
    const [resultRow, ...mainRows] = turnoversByMonths;

    return [...mainRows, ["Итого", ...resultRow.slice(1)]];
  }

  public get useItemClass(): boolean {
    const { page, pageCount } = this.paginationProps;
    return !this.search && page === pageCount;
  }

  public getDateByString(date: string): string {
    return new Date(getDateByString(date)).toISOString().slice(0, 19);
  }
}

export default TurnoversTable;
