<script>
import { Line, mixins } from "vue-chartjs";

export default {
  name: "Chart",
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "chartOptions"],
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>

<style lang="scss" scoped>
.chart {
  #line-chart {
    width: 100% !important;
  }
}
</style>
